// var oldScrollY = null

/**
 * Gestion menu navbar + header
 */
export function handleNav() {
  // gestion dropdown sur la souris
  const header = document.querySelector('#header')
  const dTs = header.querySelectorAll('.dropdown-toggle') // ou .getElementsByClassName('dropdown-toggle')

  // navbar toggler
  const toggleNav = function () {
    const nav = document.querySelector(this.getAttribute('data-trigger'))
    nav.classList.toggle('show')
    document.querySelector('.navbar-overlay').classList.toggle('visible')
    document.body.classList.add('open')
  }
  document.querySelectorAll('[data-trigger]').forEach((el) => {
    el.addEventListener('click', toggleNav)
  })
  const closeNav = function () {
    document.querySelectorAll('.navbar-collapse').forEach((el) => {
      el.classList.remove('show')
    })
    document.querySelector('.navbar-overlay').classList.remove('visible')
    document.body.classList.remove('open')
  }
  document.querySelectorAll('.navbar-close').forEach((el) => {
    el.addEventListener('click', closeNav)
  })
  document.querySelector('.navbar-overlay').addEventListener('click', closeNav)
}

/**
 * Sticky header
 */
export function sticky() {
  // if (oldScrollY !== null && oldScrollY > window.scrollY) {
    const h = document.getElementById('header')
    const s = 'sticky'
    const b = document.body
    const w = document.querySelector('html')
    const bp = 1199 - 40
    //console.log('window.scrollY', window.scrollY)
    //console.log('document.body', document.querySelector('html').scrollTop)
    //console.log('h.offsetTop', h.offsetTop)
    if (
      (w.scrollTop > 153 && w.offsetWidth > bp) ||
      (w.scrollTop > 80 && w.offsetWidth <= bp)
    ) {
      h.classList.add(s)
    } else {
      h.classList.remove(s)
    }
  // }
  // oldScrollY = window.scrollY
}
